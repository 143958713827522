// app/javascript/controllers/calendar_controller.js
import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import {BootstrapTheme} from "@fullcalendar/bootstrap5/internal";
// Import plugins...

export default class extends Controller {
  static values = { 
    id: String,
    events: Array,
    config: Object
  }
  
  static targets = [ "calendar" ]
  
  connect() {
    console.log('connect')
  }

  initialize() {
    console.log('initialize')
    var defaultView = (localStorage.getItem(`fcDefaultView-${this.idValue}`) !== null ? localStorage.getItem(`fcDefaultView-${this.idValue}`) : "wday");
    BootstrapTheme.prototype.classes.button = 'btn btn-neutral btn-sm shadow-none';
    console.log(this.configValue)
    this.calendar = new Calendar(this.calendarTarget, Object.assign({
      events: this.eventsValue,
      select: this.handleSelect.bind(this),
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin, interactionPlugin, multiMonthPlugin ],
      themeSystem: 'bootstrap5',
      allDaySlot: false,
      slotMinTime: '08:00:00',
      slotMaxTime: '20:00:00',
      contentHeight: '100',
      initialView: 'wday',
      eventClick: (info) => {
        info.jsEvent.preventDefault()
        console.log('event click')
        Turbo.visit(info.event.url, { frame: 'modal' })
      },
      headerToolbar: {
        left: '',
        center: '',
        right: ''
      },
      views: {
        wday: {
          type: 'timeGridWeek',
          hiddenDays: [0, 6],
          title: 'Week',
          buttonText: 'week',
          dayHeaderFormat: {
            weekday: 'long'
          }
        }
      },
      defaultView: 'wday',
      datesSet: arg => {
        console.log(arg.view.type)
        localStorage.setItem(`fcDefaultView-${this.idValue}`, arg.view.type);
      }
    }, this.configValue))
    this.calendar.render()
    
    this.calendar.changeView(defaultView)
  }

  handleSelect(info) {
    this.dispatch("dateselect", { detail: info })
  }
  
  eventsValueChanged(value, previousValue) {
    console.log('eventsValueChanged')
    console.log(this.calendar)
    this.reconnect()
  }

  disconnect() {
    console.log('disconnecting')
    this.calendar?.destroy()
  }

  reconnect() {
    console.log('RECONNECTING')
    console.log(this.calendar)
    
    this.calendar.removeAllEvents()
    this.calendar.addEventSource(this.eventsValue)
    // console.log('reconnecting')
    // this.calendar.destroy()
    // this.connect()
  }
  
  reset() {
    this.calendar.destroy()
  }
}