import "@hotwired/turbo-rails"
import "../controllers"
import "../turbo-mount"

import "trix"
import "@rails/actiontext"

import "../config"
import "../channels"


Turbo.StreamActions.refresh_frame = function () {
  const frame = this.getAttribute('frame')
  if (document.querySelector(`#${frame}`)) {
    Turbo.visit(window.location.href, { frame: frame })
  }
}

Turbo.StreamActions.redirect = function () {
  const frame = this.getAttribute('frame') || '_top'
  Turbo.visit(this.target, { frame: frame });
};

// https://github.com/hotwired/turbo/issues/1300#issuecomment-2323770776
Turbo.FrameElement.delegateConstructor.prototype.proposeVisitIfNavigatedWithAction = function(frame, action = null) {
  this.action = action

  if (this.action) {
    // const pageSnapshot = PageSnapshot.fromElement(frame).clone()
    const pageSnapshot = Turbo.PageSnapshot.fromElement(frame).clone()
    const { visitCachedSnapshot } = frame.delegate

    // frame.delegate.fetchResponseLoaded = async (fetchResponse) => {
    frame.delegate.fetchResponseLoaded = (fetchResponse) => {
      if (frame.src) {
        const { statusCode, redirected } = fetchResponse
        // const responseHTML = await fetchResponse.responseHTML
        const responseHTML = frame.ownerDocument.documentElement.outerHTML
        const response = { statusCode, redirected, responseHTML }
        const options = {
          response,
          visitCachedSnapshot,
          willRender: false,
          updateHistory: false,
          restorationIdentifier: this.restorationIdentifier,
          snapshot: pageSnapshot
        }

        if (this.action) options.action = this.action

        // session.visit(frame.src, options)
        Turbo.session.visit(frame.src, options)
      }
    }
  }
}

import Dropdown from 'bootstrap/js/dist/dropdown'
// import Accordion from 'bootstrap/js/dist/ac'
import Collapse from 'bootstrap/js/dist/collapse'

document.addEventListener('turbo:frame-missing', function (event) {
  event.preventDefault()
  event.detail.visit(event.detail.response)
})

document.addEventListener('turbo:before-render', function (event) {
  console.log(event)
})



import posthog from 'posthog-js'
if (document.querySelector("meta[name='current-user']")) {
  let posthogKey = document.querySelector("meta[name='posthog-key']").getAttribute('content')
  posthog.init(posthogKey,
    {
      ui_host: 'https://us.posthog.com',
      persistence: 'localStorage',
      person_profiles: 'identified_only',
      // disable_session_recording: true,
      session_recording: {
        maskTextSelector: '.ph-mask'
      }
    }
  )
  
  posthog.identify(document.querySelector("meta[name='current-user']").getAttribute('content'))

  // const toggleSessionReplay = () => {
  //   if (window.location.href.includes('schedule')) {
  //     console.log('starting')
  //     posthog.startSessionRecording()
  //   } else {
  //     posthog.stopSessionRecording()
  //   }
  // }

  // document.addEventListener('turbo:load', toggleSessionReplay)

  window.posthog = posthog
}

const events = [
  // Document events
  "turbo:click",
  "turbo:before-visit", 
  "turbo:visit",
  "turbo:before-cache",

  // Page refresh events
  "turbo:morph",
  // "turbo:before-morph-element",
  // "turbo:before-morph-attribute", 
  // "turbo:morph-element",

  // Form events
  "turbo:submit-start",
  "turbo:submit-end",

  // Frame events
  "turbo:frame-load",
  "turbo:frame-render", 
  "turbo:frame-missing",
  "turbo:before-frame-render",

  // Stream events
  "turbo:before-stream-render",

  // HTTP Request events
  "turbo:before-fetch-request",
  "turbo:before-fetch-response",
  "turbo:fetch-request-error",

  // Other events
  "turbo:load",
  "turbo:render",
  "turbo:before-render",
  "turbo:before-prefetch"
]

events.forEach(event => {
  addEventListener(event, (e) => {
    // Log both event name and details
    console.log(event, e.detail);
  });
});