import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]
  
  connect() {
    console.log("Course days controller connected")
  }

  handleSelect(event) {
    const { detail } = event
    console.log("Course day selected:", detail)
    
    // Navigate to new course day form with the selected date
    const url = this.element.dataset.newCourseDayPath
    if (url) {
      Turbo.visit(`${url}?starts_at=${detail.start.toISOString()}`, { frame: 'modal' })
    }
  }
} 